import { render, staticRenderFns } from "./index.vue?vue&type=template&id=65d747bb&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=65d747bb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d747bb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/usr/src/app/src/components/atoms/Heading.vue').default,RPHNearestProductsGrid: require('/usr/src/app/src/components/v2/organisms/RPHNearestProductsGrid/index.vue').default})
