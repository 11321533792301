
import Vue, { PropType } from 'vue'
import { IProduct } from '~/types/DTO/products'

export default Vue.extend({
  name: 'RPHPopularProductCard',
  components: {
    Heading: () => import('~/components/atoms/Heading.vue'),
  },
  props: {
    productData: {
      type: Object as PropType<IProduct>,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    description(): string {
      const { description, short_description } = this.productData?.annotation
      return short_description || description || ''
    },

    availablePrice(): string {
      return this.$services.price.getDefaultPrice(this.productData?.prices as any)
    },

    price(): any[] {
      const { fast_delivery, hot_price, standard } = this.productData?.prices
      return [hot_price.price || fast_delivery.price || standard.price, !!hot_price.price]
    },
  },
  methods: {},
})
