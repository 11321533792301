
import Vue, { PropType } from 'vue'
import { IProduct } from 'types/DTO/products'
import { EventsViewedCategory } from '~/enums/eventsViewedCategory'

export default Vue.extend({
  name: 'RPHPopularProductGrid',
  components: {
    RPHPopularProductCard: () => import('rph/molecules/RPHPopularProductCard/index.vue'),
  },
  props: {
    popularProducts: {
      type: Array as PropType<IProduct[]>,
    },
  },

  mounted() {
    this.$services.productsEvents.productViewPushEvent({
      products: this.popularProducts,
      itemListData: {
        item_list_id: EventsViewedCategory.Popular.id,
        item_list_name: EventsViewedCategory.Popular.name,
      },
    })
  },
})
