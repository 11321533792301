
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHPopularProductBlock',
  components: {
    RPHPopularProductGrid: () => import('rph/organisms/RPHPopularProductGrid/index.vue'),
    Heading: () => import('~/components/atoms/Heading.vue'),
  },
  computed: {
    popularProducts() {
      return this.$store.getters['rph-products/popularProducts']
    },
  },
})
